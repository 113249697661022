import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PORT_API } from "../../Redux/Actions/ActionsTypes";
import metrics from "../../assets/metricas.json";

import colors from "../../styles/colors";
import { calculateTimeDiff, timeDiff } from "../../Utils/time";

function Metrics() {
  const { id } = useParams();

  const [metric, setMetric] = useState({ isLoading: true, data: null });

  const getMetrics = useCallback(async () => {
    setMetric({ isLoading: true, data: null });
    const { data } = await axios.get(`${PORT_API}/metrics/${id}`);

    const _aux = [];
    data.forEach((m) => {
      if (!m || _aux.find((p) => p?.estado_wms === m.estado_wms)) return null;

      const _aux_eventos = new Map();
      if (m.eventos?.length) {
        m.eventos.forEach((e) => {
          _aux_eventos.set(e.EstadoId, { ...e, Fecha: new Date(e.Fecha) });
        });
      }

      const fechas = {
        fecha_wms: new Date(m.fecha_wms),
        fecha_ingreso: new Date(m.fecha_ingreso),
        fecha_aprobado: new Date(m.fecha_aprobado),
        fecha_etiqueta: new Date(m.fecha_etiqueta),
      };

      const common = {
        ...m,
        eventos: [..._aux_eventos.values()].sort(
          (a, b) => a.Fecha.getTime() - b.Fecha.getTime()
        ),
      };
      if (m.estado_wms === "pendiente") _aux[0] = { ...common, ...fechas };
      if (m.estado_wms === "preparado") _aux[1] = { ...common, ...fechas };
      if (m.estado_wms === "despachado") _aux[2] = { ...common, ...fechas };
    });

    setMetric({ isLoading: false, data: _aux });
  }, [id]);

  useEffect(() => {
    getMetrics();
  }, [getMetrics]);

  const x = metric.data?.filter((m) => !!m) || [];

  return (
    <Container>
      <h1>Metricas del pedido {id}</h1>

      {metric.data ? (
        <div className="metrics-container">
          <div className="metadata">
            <p className="cp">Codigo Postal: {x[0].codigo_postal}</p>
            <p className="SLA">
              SLA:{" "}
              {
                metrics.find(
                  (m) => m.cp.toString() === x[0].codigo_postal.toString()
                ).sla
              }
            </p>
            <p className="">
              Lead Time:{" "}
              {metrics.find(
                (m) => m.cp.toString() === x[0].codigo_postal.toString()
              ).sla <
              getMetadata(
                metric.data[metric.data.length - 1].fecha_wms,
                x[0] && x[0].eventos && x[0].eventos.length
                  ? x[0].eventos[x[0].eventos.length - 1].Fecha
                  : new Date()
              )
                ? "Delay"
                : "On Time"}
            </p>
          </div>

          <div className="sections">
            <div className="inline">
              <section className="tango">
                <p className="title">tango</p>

                <div className="inline">
                  <div className="step">
                    <p className="name">ingreso</p>
                    <time>{x[0].fecha_ingreso.toLocaleString()}</time>
                  </div>

                  <div className="step">
                    <p className="name">aprobacion</p>
                    <time>{x[0].fecha_aprobado.toLocaleString()}</time>
                  </div>
                </div>

                <div className="total">
                  <p>Tiempo en Tango:</p>
                  <time>
                    {calculateTimeDiff(x[0].fecha_ingreso, x[0].fecha_aprobado)}
                  </time>
                </div>
              </section>

              <section className="wms">
                <p className="title">wms</p>
                <ul className="inline">
                  {metric.data.map((m) => (
                    <li>
                      <section className="step">
                        <p className="name">{m.estado_wms}</p>
                        <time className="time">
                          {m.fecha_wms.toLocaleString()}
                        </time>
                      </section>
                    </li>
                  ))}
                </ul>

                {x[0]?.estado_wms !== x[x.length - 1]?.estado_wms ? (
                  <div className="total">
                    <p>Tiempo en WMS:</p>
                    <time>
                      {calculateTimeDiff(
                        x[0].fecha_wms,
                        x[x.length - 1].fecha_wms
                      )}
                    </time>
                  </div>
                ) : null}
              </section>

              <section className="andreani">
                <p className="title">andreani</p>

                <div className="inline">
                  <div className="step">
                    <p className="name">Creacion Etiqueta</p>
                    <time>{x[0].fecha_etiqueta.toLocaleString()}</time>
                  </div>

                  {x[0].eventos.map((e) => (
                    <div className="step">
                      <p className="name">{e.Estado}</p>
                      <time>{e.Fecha.toLocaleString()}</time>
                    </div>
                  ))}
                </div>

                <div className="total">
                  <p>Tiempo en Andreani:</p>
                  <time>
                    {calculateTimeDiff(
                      x[0].fecha_etiqueta,
                      x[0] && x[0].eventos && x[0].eventos.length
                        ? x[0].eventos[x[0].eventos.length - 1].Fecha
                        : new Date()
                    )}
                  </time>
                </div>
              </section>
            </div>

            <section className="total">
              <p>Tiempo total</p>
              <time>
                {calculateTimeDiff(
                  x[0].fecha_ingreso,
                  x[0] && x[0].eventos && x[0].eventos.length
                    ? x[0].eventos[x[0].eventos.length - 1].Fecha
                    : new Date()
                )}
              </time>
            </section>
          </div>
        </div>
      ) : null}
    </Container>
  );
}

export default Metrics;

function getMetadata(d1, d2) {
  const { days } = timeDiff(d1, d2);
  console.log(days);

  return null;
}

const Container = styled.div`
  position: relative;
  padding: 0 0 0 var(--navbar-size);
  min-height: 100vh;
  width: 100%;

  .metadata {
    font-size: 1rem;
  }

  > h1 {
    position: relative;
    margin: 0 0 5rem 0 !important;
    /*color: #${colors.hex.primary};*/
    text-align: center;
    font-size: 1.25rem;
    background-color: #${colors.hex.primary_400};
    color: #fff;
    padding: 2rem 0;

    ::after,
    ::before {
      content: "";
      position: absolute;
      border: 1rem solid #fff5;
    }

    ::before {
      bottom: 0rem;
      right: 10%;
      transform: translate(50%, 50%);
      width: 20rem;
      height: 20rem;
      border-radius: 100%;
    }

    ::after {
      top: 0rem;
      left: 3rem;
      transform: translate(-50%, -50%);
      width: 15rem;
      height: 15em;
      border-radius: 100%;
    }
  }

  .inline {
    list-style: none;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .metrics-container {
    padding: 0 2rem 0 calc(var(--navbar-size));
  }

  .sections {
    position: relative;
    margin: 4rem 0 0 0;
    width: 100%;

    > .inline {
      position: relative;
      display: grid;
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  }

  .wms,
  .tango,
  .andreani {
    position: relative;

    .title {
      margin: 0 0 0.5rem 0;
      padding: 0.5rem 0.75rem;
      border-radius: 2px;
      text-align: center;
      background: #${colors.hex.primary};
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .wms,
  .andreani {
    .inline {
      overflow-x: scroll;
      padding: 0 0 1rem 0;
    }
  }

  .total {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    position: absolute;
    padding: 0.5rem;
    border: 1px solid #aaa;
    border-radius: 0 0 5px 5px;
    border-top: none;
    top: calc(100% + 1rem);
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .sections > .total {
    top: auto;
    border: 1px solid #ccc;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    bottom: calc(100% + 1rem);
  }

  .step {
    text-align: center;
    padding: 0.25rem 1rem;
    white-space: nowrap;

    .name {
      font-weight: 600;
      font-size: 0.95rem;
      text-transform: capitalize;
      margin: 0 0 0.25rem 0;
    }

    .time {
    }
  }
`;
